// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "controllers";
import Trix from "trix"
import "@rails/actiontext";


//const filesContext = require.context(`./clients/${process.env.CLIENT_NAME}`, true, /\.js$/);
//filesContext.keys().forEach(filesContext);

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// To add new Trix formatting icons in the toolbar
Trix.config.textAttributes.sup = { tagName: "sup", inheritable: true };
Trix.config.textAttributes.sub = { tagName: "sub", inheritable: true };
Trix.config.textAttributes.underline = { tagName: "u", inheritable: true };
Trix.config.textAttributes.color = { styleProperty: 'color', inheritable: true };

document.addEventListener("trix-initialize", function(event) { 
  var element = event.target
  var editor = element.editor
  var toolbarElement = element.toolbarElement
  var groupElement = toolbarElement.querySelector(".trix-button-group.trix-button-group--text-tools")
  var groupThird = toolbarElement.querySelector(".trix-button-group.trix-button-group--file-tools")
  var groupTools = toolbarElement.querySelector(".trix-button-group.trix-button-group--block-tools")
  var linkSection =  groupElement.querySelector('button.trix-button--icon-link')
  linkSection.remove();

  groupElement.insertAdjacentHTML("beforeend",'<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline"><sub>Underline</sub></button>')
  groupElement.insertAdjacentHTML("beforeend",'<input type="color" class="trix-button trix-button--icon trix-button--icon-color"/>')
  groupElement.insertAdjacentHTML("beforeend", '<button type="button" class="trix-button trix-button--icon trix-button--icon-sup" data-trix-attribute="sup"><sup>Superscript</sup></button>')
  groupElement.insertAdjacentHTML("beforeend",'<button type="button" class="trix-button trix-button--icon trix-button--icon-sub" data-trix-attribute="sub"><sub>Subscript</sub></button>')

  /*
  groupTools.insertAdjacentHTML("beforeend",'<button type="button" class="trix-button trix-button--icon trix-button--icon-align-left" data-trix-attribute="alignLeft" title="Align Left" tabindex="-1">Align Left</button>')
  groupTools.insertAdjacentHTML("beforeend",'<button type="button" class="trix-button trix-button--icon trix-button--icon-align-center" data-trix-attribute="alignCenter" title="Align Left" tabindex="-1">Align Center</button>')
  groupTools.insertAdjacentHTML("beforeend",'<button type="button" class="trix-button trix-button--icon trix-button--icon-align-right" data-trix-attribute="alignRight" title="Align Right" tabindex="-1">Align Right</button>')
  */

  groupThird.insertAdjacentHTML("beforeend",'<button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="Link" tabindex="-1">Link</button>')

  var selectedAttributes = new Set
  function updateSelectedAttributes() {
    selectedAttributes = new Set

    var selectedRange = editor.getSelectedRange()
    if (selectedRange[0] === selectedRange[1]) { selectedRange[1]++ }

    var selectedPieces = editor.getDocument().getDocumentAtRange(selectedRange).getPieces()
    selectedPieces.forEach(function(piece) {
      Object.keys(piece.getAttributes()).forEach(function(attribute) {
        selectedAttributes.add(attribute)
      })
    })
  }

  function enforceExclusiveAttributes() {
    if (editor.attributeIsActive("sup") && selectedAttributes.has("sub")) {
      editor.deactivateAttribute("sub")
      updateSelectedAttributes()
    } else if (editor.attributeIsActive("sub") && selectedAttributes.has("sup")) {
      editor.deactivateAttribute("sup")
      updateSelectedAttributes()
    }
  }

  updateSelectedAttributes()
  element.addEventListener("trix-selection-change", updateSelectedAttributes)
  element.addEventListener("trix-change", enforceExclusiveAttributes)

  if(element.dataset.content){
    editor.insertHTML(element.dataset.content)
  }

  /* Piste à approfondir

  var color_picker= document.querySelector(".trix-button--icon-color")
  color_picker.addEventListener('input', function(event){
    console.log('cp select');
  })
  color_picker.addEventListener('change', function(event){
    //console.log('cp changed');
    editor.activateAttribute('color', event.target.value);
  }) */
})
